/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import axios from 'axios';  
import React from 'react';  
import { useLocation, Link } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
// @mui material components
import Card from "@mui/material/Card";
import SoftInput from "components/SoftInput";


// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Helmet } from "react-helmet";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import {useEffect, useState, useRef} from 'react';
//import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import FormSelect from 'react-bootstrap/FormSelect';
import {getPlayerProfile} from 'Utils.js'
import gr_logo from "assets/images/group.png";

// Data
//import membersTableData from "layouts/groups/data/membersTableData";

import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { Navigate } from "react-router-dom";
import SoftButton from "components/SoftButton";
import Modal from 'react-modal';

import whatsapp_logo from "assets/images/logos/whatsapp.png";
import telegram_logo from "assets/images/logos/telegram.png";
import { FaMinusCircle } from "react-icons/fa";
import { FaPenSquare } from "react-icons/fa";
import { FaBackward } from "react-icons/fa";
import { FaForward } from "react-icons/fa";
import { FaImages } from "react-icons/fa";

import { alertTitleClasses } from '@mui/material';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {zIndex: 100000}   
};


function Author({ image, name, email }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column" >
        <SoftTypography variant="button" fontWeight="medium" color="info">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
};
function Function({ job, org }) {
  return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {org}
      </SoftTypography>
    </SoftBox>
  );
};
let global_group_id='';
export default class Viewmembers extends React.Component {  
  constructor(props) {
    super(props);
    this.setState({loggedIn: '1'});
    this.setState({showModal: false,isChecked:false});
    
    window.helloComponent = this;

    
    this.state = { loggedIn: '1',showModal: false,viewstatus:'list',linkrows:[],group_logo:team2,groupname:'Cricket Group',group_normal_count: 14, group_guest_count : 2};

    let search = window.location.search;
    let params = new URLSearchParams(search);

     global_group_id = params.get('id')
    if(global_group_id==null)
    {
      global_group_id=  '';
    }


    let sid = params.get('sid')
    if(sid==null)
    {
      sid=  '';
    }
    let series_id = '';
    if(sid!=''){
      series_id = atob(sid);
    }

    let csid = params.get('csid')
    if(csid==null)
    {
      csid=  '';
    }
    let cseries_id = '';
    if(csid!=''){
      cseries_id = atob(csid);
    }
    if(series_id  !='')
    {
     
      setTimeout(
        () => this.setState({viewstatus: 'createlink'}), 
        1500
      );
      //this.setState({viewstatus: 'createlink'});
    }else if(cseries_id !=  '')
    {
      setTimeout(
        () => this.setState({viewstatus: 'createlink'}), 
        1500
      );
    }
    
//    this.setState({linkData:{rows:[]}});

  this.ref_link_heading = React.createRef();

  this.ref_link_heading_link = React.createRef();
    
  }
  state = {  
    normalmembers: []  ,
    guestmembers:[]

  }  

  showModal (group_fk,user_fk) {
    getPlayerProfile(group_fk,user_fk,'i_profile_modal');
    //call API i_profile_modal
    //alert(group_fk + ' ' + user_fk)
    this.setState({
      show: !this.state.show
    });
  };
  closeModal = e => {
    this.setState({
      show: false
    });
  
};
  
ondImageChange = event => {
  if (event.target.files && event.target.files[0]) {
    let img = event.target.files[0];
   // alert(img)

    const formData = new FormData();
  // alert(document.getElementById('dp_member_id').value)
    formData.append("profile_image", event.target.files[0]);
    formData.append("dp_member_id", this.state.selected_member);
    //call API
    console.log(formData)

    const pconfig = {     
      headers: { 'content-type': 'multipart/form-data' }
  }

    axios.post( global.config.app.backend_api_url + `members/uploadprofilepic_byadmin`,formData,pconfig, { withCredentials: true }
    )  
      .then(res => {  
        //const normalmembers = res.data;  
        console.log("after112")
        console.log(res.data)
        if(res.data.status=='0')
        {
          alert(res.data.message);
        }
        else
        {
          alert(res.data.message);
          //alert(res.data.profile_pic)
          /*
          this.setState({
            image: URL.createObjectURL(img)
          });
          */
          this.setState({ viewstatus: 'list'});

        }
  
      });

      

   
  }
};

clickedAddMember(val_id,name){
  // alert("Called from outside"+val_id);

   document.getElementById('j_member_id').value    = val_id;
   document.getElementById('member_name').value    = name;
   document.getElementById('member_name').readOnly = true;
   document.getElementById("member_name").style.backgroundColor = "#EBEBE4";


   
}


onKeyDownSearch = (e)=> {

  if(this.state.selected_member !='')
  {
    return;
  }
  const j_mobile_no = document.getElementById('mobile_no').value

  document.getElementById('j_member_id').value    = '';
  //this.ref_j_mobile_no.current  = j_mobile_no;

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let group_code = atob(params.get('id'));
  if(group_code==null)
{
  group_code  = '';
}

  const post_data = { mobile_no: j_mobile_no,group_id : group_code,from_add_to_group:1 };
    
//      alert(group_id)
//,{headers: {"Content-Type": "application/json"}}
  axios.post( global.config.app.backend_api_url + `members/search`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      
      
      document.getElementById("j_members_list").innerHTML=  '';
      var html_content = '';
      if(res.data.status!='0')
      {
        console.log(res.data)
        //const normalmembers = this.createMemberRows(res.data);
        var iloop ;
        for(iloop=0; iloop < res.data.length; iloop++){
            const full_name = res.data[iloop].full_name;
            const mobile_no = res.data[iloop].mobile_no;
            var m_user_id = res.data[iloop].user_id;
            //console.log(full_name)

            

            //html_content = html_content + createsearchlist(full_name)
            html_content = html_content + (iloop+1) + '. '+ full_name +' ( '  + mobile_no + " ) <a href='javascript:void(0)' onclick='window.helloComponent.clickedAddMember(&#39;"+m_user_id+"&#39;,&#39;"+full_name+"&#39;)'>Select</a><br>";

        }

        document.getElementById("j_members_list").innerHTML=html_content;
        const links_columns1 = [{ name: "full_name", align: "left" },
        { name: "mobile_no", align: "left" }];

       

      }else
      {
        document.getElementById("j_members_list").innerHTML=  'No members found.';

      }
      


      

    });

  

//    alert('API to be called..'+this.ref_j_mobile_no.current);
}


onBacklist = (e)=> {
  this.callbasicapi();
  this.setState({viewstatus: 'list'});

}
checkIsDraft= (e)=> {
  
  if(document.getElementById('s_is_draft').value=="1")
  {
    document.getElementsByClassName("draft_item")[0].style.display='';
  }
  else
  {
    document.getElementsByClassName("draft_item")[0].style.display='none';
  }
}
onClickAddLink = (e)=> {

  var j_mobile_no = document.getElementById('mobile_no').value
  var j_member_id = '';

  var j_keep1_checked = document.getElementById('j_keep1').checked;

  var j_full_name = '';
  try{
    j_full_name = document.getElementById('member_name').value
    
  }catch(Err)
  {
  }

  try{
    j_member_id = document.getElementById('j_member_id').value        
  }catch(Err)
  {
  }
  var overall_user_rating = document.getElementById('overall_user_rating').value
  var overall_comments = document.getElementById('overall_comments').value
  var public_description_about_member = document.getElementById('public_description_about_member').value

  var cric_heroes_profile_link = document.getElementById('cric_heroes_profile_link').value
  var member_type = document.getElementById('member_type').value

 var group_admin= document.getElementById('group_admin').value


  let search = window.location.search;
  let params = new URLSearchParams(search);
  let group_fk = atob(params.get('id'));

  

  const post_data = { group_admin:group_admin, member_type: member_type,member_id : j_member_id,member_name:j_full_name,group_fk:group_fk,member_mobile_no:j_mobile_no,overall_user_rating:overall_user_rating,overall_comments:overall_comments,public_description_about_member:public_description_about_member,cric_heroes_profile_link:cric_heroes_profile_link };
    console.log(post_data)
//      alert(group_id)
//,{headers: {"Content-Type": "application/json"}}
  axios.post( global.config.app.backend_api_url + `groups/add_edit_member`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)
      if(res.data.status=='0')
      {
        alert(res.data.message);
      }
      else
      {
        alert(res.data.message);
        document.getElementById('mobile_no').value  = '';

        try{
          document.getElementById('member_name').value  = '';
        }catch(Err)
        {
        }
        try{
          document.getElementById('j_member_id').value  = '';
        }catch(Err)
        {
        }
        document.getElementById('overall_user_rating').value  = '';
        document.getElementById('overall_comments').value  = '';
        document.getElementById('public_description_about_member').value  = '';
        document.getElementById('cric_heroes_profile_link').value  = '';
        
        if(!j_keep1_checked)
        {
          this.callbasicapi();
          this.setState({viewstatus: 'list'});
        }
      }

    });



// alert('API to be called');
}

deleteMemberRow(mid, e){  

  if(window.confirm('Sure?') ==  false)
  {
    return;
  }
  //alert("deleted id : "+ id);
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let group_id = atob(params.get('id'));

  console.log( this.state.normalmembers);
  //const normalmembers = this.state.normalmembers.filter(item => item.id !== id);  
  //this.setState({ normalmembers }); 
  
  const post_data = { group_fk: group_id,member_id:mid };
   
  axios.post( global.config.app.backend_api_url + `groups/remove_member`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data; 
      if(res.data.status=='0')
      {
        alert(res.data.message);
      } 
      else{
        alert(res.data.message);
        this.callbasicapi();
        this.setState({viewstatus: 'list'});
        
      }


      

    });


  
}  

callNotificationSubscribe(appid,userid)
{
  if(userid=='' || appid=='')
  {
    return;
  }
  const s = document.createElement("script")
  s.src = "https://notix.io/ent/current/enot.min.js"
  s.onload = (sdk) => {
      sdk.startInstall({
          appId: appid,
          loadSettings: true,
          disableConsoleDebug: true,
          user: userid
      });

  }
  document.head.append(s);
}

  componentDidMount() { 
      /*
    axios.get(global.config.app.backend_api_url +`groups/setsession?user_id=2`, { withCredentials: true })//, { withCredentials: true }  
      .then(res => {  

      });*/
      const post_data1 = { lo : 1};

      axios.post( global.config.app.backend_api_url + `login/sessionavailable`,post_data1, { withCredentials: true }
      )  
        .then(res => {  
          console.log(res.data)
          var user_id = res.data.user_details.user_id;
          
          if (user_id !== undefined && user_id !== null && user_id !== 'null') 
          {

            
          }else
          {
            window.location.replace('sign-in');
            //return;
          }

          if(res.data.user_details.user_id!='')
          {
            this.callNotificationSubscribe(res.data.user_details.nai,res.data.user_details.user_id);
          }
    
        });

        
        this.callbasicapi();
        
      
  }  

  callbasicapi()
  {
    let search = window.location.search;
      let params = new URLSearchParams(search);
      let group_id = atob(params.get('id'));



    
      const post_data = { group_id: group_id };
      
    // alert(group_id)
//,{headers: {"Content-Type": "application/json"}}
    axios.post( global.config.app.backend_api_url + `groups/getbyid`,post_data, { withCredentials: true }
    )  
      .then(res => {  
        //const normalmembers = res.data;  
        console.log("after112d")
        console.log(res.data.group_data)
//        alert(res.data.group_data[0].is_admin)
        this.setState( {group_logo: res.data.group_data[0].group_logo,groupname:res.data.group_data[0].groupname,group_normal_count: res.data.group_data[0].normal_members_count, group_guest_count : res.data.group_data[0].guest_members_count,group_admin_flag : res.data.group_data[0].is_admin});

        const normalmembers = this.createMemberRows(res.data.normal_members);
        console.log("getting response "+res.data.normal_members)
        //this.setState({ normalmembers });  

        const guestmembers = this.createMemberRows(res.data.guest_members);
        console.log("getting response ")
        this.setState({ normalmembers,guestmembers,series_notification_type: res.data.series_notification_type});  

       // series_notification_type


        

      });
      /*
      axios.get(global.config.app.backend_api_url +`testAPIp`, { withCredentials: true })  
      .then(res => {  
        this.setState({loggedIn: res.data.loggedIn, designation: "your value"});

      });*/

  }
    
 onClickGenerateLink = (e)=> {

  
  var s_series_name = document.getElementById('s_series_name').value
  var s_match_date = document.getElementById('s_match_date').value
  var s_from_time = document.getElementById('s_from_time').value
  var s_to_time = document.getElementById('s_to_time').value
  var s_header_description = document.getElementById('s_header_description').value
  var s_footer_description = document.getElementById('s_footer_description').value
  var s_total_participants = document.getElementById('s_total_participants').value
  var s_total_guests_participants= document.getElementById('s_total_guests_participants').value

  var s_total_waiting_list = document.getElementById('s_total_waiting_list').value
  var s_add_delete_permission_cutoff_mins = document.getElementById('s_add_delete_permission_cutoff_mins').value
  var show_join_link_group_members_only= document.getElementById('show_join_link_group_members_only').value;

  var enable_browser_push= document.getElementById('enable_browser_push').value;
  var enable_email_push= document.getElementById('enable_email_push').value;
  var enable_telegram_push= document.getElementById('enable_telegram_push').value;
  var enable_whatsapp_push= document.getElementById('enable_whatsapp_push').value;


  var s_group_id = document.getElementById('s_group_id').value
  var s_series_id = document.getElementById('s_series_id').value
  
  var s_notify= 0;

  if(document.getElementById('s_notify').checked)
  {
    s_notify  = 1;
  }

  var s_is_draft= document.getElementById('s_is_draft').value;
  var s_notify_automatically= document.getElementById('s_notify_automatically').value;
  var photo_upload= document.getElementById('photo_upload').value;

  var s_draft_publish_date  = document.getElementById('s_draft_publish_date').value
  var s_draft_publish_time  = document.getElementById('s_draft_publish_time').value
  var mi_manual_sl_seq= document.getElementById('mi_manual_sl_seq').value

  var status_fk= document.getElementById('s_status_fk').value
  
  const post_data = {series_id : s_series_id, series_name: s_series_name,match_date : s_match_date,from_time:s_from_time,to_time:s_to_time 
    ,header_description:s_header_description ,footer_description:s_footer_description ,total_participants:s_total_participants ,
    total_waiting_list:s_total_waiting_list 
    ,add_delete_permission_cutoff_mins:s_add_delete_permission_cutoff_mins ,group_id:s_group_id, notify:s_notify,show_join_link_group_members_only:show_join_link_group_members_only
    ,enable_browser_push:enable_browser_push,enable_whatsapp_push:enable_whatsapp_push,enable_telegram_push:enable_telegram_push,
    enable_email_push:enable_email_push,s_is_draft:s_is_draft,s_draft_publish_date:s_draft_publish_date,
    s_draft_publish_time:s_draft_publish_time,status_fk:status_fk ,notify_automatically:s_notify_automatically
  ,total_guests_participants:s_total_guests_participants,mi_manual_sl_seq:mi_manual_sl_seq,
  photo_upload:photo_upload};
    console.log(post_data)
//      alert(group_id)
//,{headers: {"Content-Type": "application/json"}}
  axios.post( global.config.app.backend_api_url + `matchschedule/generatelink`,post_data, { withCredentials: true }
  )  
    .then(res => {  
      //const normalmembers = res.data;  
      console.log("after112")
      console.log(res.data)
      if(res.data.status=='0')
      {
        alert(res.data.message);
      }
      else
      {
        alert(res.data.message + ' Link is '+res.data.invite_link);
        this.setState({viewstatus: 'list'});
      }

    });


  }


    

  deleteRow(id, e){  
    console.log("deleted id : "+ id);
    console.log( this.state.normalmembers);
    const normalmembers = this.state.normalmembers.filter(item => item.id !== id);  
    this.setState({ normalmembers }); 
    /*
    axios.delete(`http://localhost:9000/groups/${id}`)  
      .then(res => {  
        console.log(res);  
        console.log(res.data);  
    
        const normalmembers = this.state.normalmembers.filter(item => item.id !== id);  
        this.setState({ normalmembers });  
      })  */
    
  }  

  membersTableColumns = {
    member_columns : [
      { name: "slno",align: "left" },
      { name: "member_name",align: "left" },
      { name: "mobile_no",align: "left" },
      { name: "added_on",align: "left" },
      { name: "action",align: "left" },
    ]
  }
  
  linkTableColumns = {
    links_columns: [
      { name: "invitelink", align: "left" },
      { name: "max_join_limit_with_link", align: "left" },
      { name: "current_join_count", align: "left" },
      { name: "link_expiry", align: "left" },
      { name: "action", align: "left" },
    ]
  };

  linkTableRows = {
    linkrows: [
      {
        invitelink: <Author image={team2} name="https://cricketgroups.in/sdfdsf"  />,
        max_join_limit_with_link: <Function job="10"  />,
        current_join_count: <Function job="1"  />,
        link_expiry: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            23/12/2023
          </SoftTypography>
        ),
        action: (
          <SoftBox>
          <SoftTypography
            component="a"
            href="javascript:void(0)"
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            Delete
          </SoftTypography>
          <SoftBox component="img" src={whatsapp_logo} alt="Share to whatsapp" width="24px" mt={1} />
          <SoftBox component="img" src={telegram_logo} alt="Share to telegram" width="24px" mt={1} />

</SoftBox>

        ),
      }
      
    ],
  };


   membersTableData = {
    columns: [
      { name: "slno", align: "left" },
      { name: "member_name", align: "left" },
      { name: "added_on", align: "left" },
      { name: "action", align: "left" },
    ],
  
    rows: [
      {
        slno : <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        1
      </SoftTypography>,
        member_name: <Author image={team2} name="Friends Group"  />,
        location: <Function job="Trivandrum"  />,
        memberscount: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            25
          </SoftTypography>
        ),
        added_on: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            12/11/2022
          </SoftTypography>
        ),
        action: (
          <SoftBox>
          <SoftTypography
            component="a"
            href="javascript:void(0)"
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            Delete
          </SoftTypography>
</SoftBox>

        ),
      }
      
    ],
  };

  createMemberRows(res)
{
  //loop here and create rows in soft UI rows
  console.log(res)
  const plainObj = [];

  var iloop ;
  for(iloop=0; iloop < res.length; iloop++){
      const thisid = res[iloop].id;
      const href_link = "/viewmembers/"+thisid;
      const g_group_fk  = res[iloop].group_id;
      const g_user_fk  = res[iloop].user_fk;
      const type_name  = res[iloop].type;
      const type_fk  = res[iloop].member_type_id;
      const is_admin_flag  = res[iloop].is_admin;

      const group_admin  = res[iloop].group_admin;
      const selected_member_name = res[iloop].member_name;
      const allow_pic_upload_by_gr_admin= res[iloop].allow_pic_upload_by_gr_admin;
      //loopData += `<li>${data[i].name}</li>`
      const arrayLike = {
        slno: <Function job={iloop+1}  />,
        member_name: (
        <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={res[iloop].profile_pic ? (res[iloop].profile_pic):(team2)} alt={res[iloop].member_name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column" >
        <SoftTypography component="a" href='javascript:void(0)'  onClick={() => this.showModal(g_group_fk,g_user_fk)} variant="button" fontWeight="medium" color="info">
        {res[iloop].member_name}
        </SoftTypography>
        
      </SoftBox>
    </SoftBox>
),
mobile_no: <Function job={res[iloop].mobile_no}  />,
        location: <Function job={res[iloop].location}  />,
        memberscount: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            25
          </SoftTypography>
        ),
        'added_on': res[iloop].added_on,
        'action' : (
          <SoftBox>
            {/*
          <SoftTypography
            component="a"
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
            onClick={() => this.deleteRow(thisid)}
          >
            Delete
          </SoftTypography>
          <SoftBox component="img" src={whatsapp_logo} alt="Share to whatsapp" width="24px" mt={1} />


          

            */
            
            }

          {
                (() =>{
            if(is_admin_flag  =='1')//this.state.group_admin_flag
            {
              return(
            <SoftTypography
            component="a"
            href="javascript:void(0)"
            variant="caption"
            color="secondary"
            title="Edit"
            fontWeight="medium"
            onClick={() => this.setState({viewstatus: 'create',member_type:type_fk,member_type_name:type_name,selected_member:g_user_fk,group_admin_type:group_admin})}
          >
        <FaPenSquare></FaPenSquare>

            
          </SoftTypography>
              )
              
            }
            })()
        
                
          }
           &nbsp;&nbsp;&nbsp;

{
                (() =>{
            if(is_admin_flag  =='1' && allow_pic_upload_by_gr_admin=='1')//this.state.group_admin_flag
            {
              return(
            <SoftTypography
            component="a"
            href="javascript:void(0)"
            variant="caption"
            color="secondary"
            title="Upload Pic"
            fontWeight="medium"
            onClick={() => this.setState({viewstatus: 'uploaduserdp',allow_pic_upload_by_gr_admin:allow_pic_upload_by_gr_admin,member_type:type_fk,member_type_name:type_name,selected_member:g_user_fk,group_admin_type:group_admin,selected_member_name:selected_member_name})}
          >
        <FaImages></FaImages>

            
          </SoftTypography>
              )
              
            }
            })()
        
                
          }
          
          &nbsp;&nbsp;&nbsp;

          {
                (() =>{
            if(is_admin_flag  =='1')//this.state.group_admin_flag
            {
              return(
          <SoftTypography
          component="a"
          title="Remove?"
          href="javascript:void(0)"
          variant="caption"
          color="secondary"
          fontWeight="medium"
          onClick={() => this.deleteMemberRow(g_user_fk)}
        >
         <FaMinusCircle></FaMinusCircle>
        </SoftTypography>
)
              
}
})()

    
}
          

          </SoftBox>
          
        ),
        'id' : res[iloop].id
      };
      Array.prototype.push.call(plainObj, arrayLike);

  }
  
    //const myObjStr = JSON.stringify(plainObj);
    console.log("obj:")
    console.log(plainObj)
    console.log("res:")
    console.log(res)
    
    return plainObj;
  }

  
  


  render() {  

    const handleClose = () => {
      this.setState({showModal: false});
    }
    const handleShow = () => {
      this.setState({showModal: true});
    }

    if(this.state.loggedIn!=='1')
    {
      return <Navigate to="/sign-in" />
  
    };

  const { columns, rows } = this.membersTableData;

  const {links_columns} = this.linkTableColumns;

  const {linkrows} = this.linkTableRows;
  const {member_columns} = this.membersTableColumns;
  //const [list, setList] = useState([]);
  //const [actuallist, setActuallist] = useState([]);

    console.log("sess_data:");  
    console.log(this.state.loggedIn);  

    let link_to_schedules = "/schedules";

    let search1 = window.location.search;
    let params1 = new URLSearchParams(search1);
    let group_id1 = atob(params1.get('id'));
    
    let querystring1 = "?g="+btoa(group_id1);



    return (  
      <DashboardLayout>
      <Helmet>
      <title>Group Members | {global.config.app.name}</title>
      <meta
        name="description"
    content="Join Our Cricket Team - Experience the thrill of competitive cricket and be part of a supportive community. Sign up now and showcase your skills on the field."
      />
      <meta
        name="keywords"
        content="Cricket, team, join, competitive, skills, community, sign up, field"
      />
    </Helmet>
      <DashboardNavbar />
      <SoftBox py={3}>
      <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>

            <SoftAvatar
              src={this.state.group_logo ? (this.state.group_logo):(gr_logo)}
              alt="profile-image"
              variant="rounded"
              size="xl"
              shadow="sm"
            />

              {this.state.groupname} |&nbsp;&nbsp;&nbsp;&nbsp;
              {this.state.group_normal_count} Nomral Members |&nbsp;&nbsp;&nbsp;&nbsp;
              {this.state.group_guest_count} Guest Members

              <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              {
                (() => {
                  let search = window.location.search;
                    let params = new URLSearchParams(search);
                    let group_id = atob(params.get('id'));

                    

                  if(this.state.group_admin_flag == '1' ){ 

                 // var link_to_create = "/creatematchlink?group_id="+btoa(group_id);


                  let search = window.location.search;
                  let params = new URLSearchParams(search);
                  let series_id ='';
                  let c_series_id ='';
                  if(params.get('sid')!=null)
                  {
                    series_id = atob(params.get('sid'));
                  }
                  if(params.get('csid')!=null)
                  {
                    c_series_id = atob(params.get('csid'));
                  }

                  
                  
                  var caption_cl = 'Generate new match schedule link';
                  if(series_id!='')
                  {
                    caption_cl = 'Edit match link';
                  }/*else if(c_series_id!='')
                  {
                    caption_cl = 'Copy and generate a new match link';
                  }*/
                  
                  


                   return (

      
                    <SoftButton variant="gradient" size="small" color="info"  onClick={() => this.setState({viewstatus: 'createlink'})}>
                    {caption_cl}
              </SoftButton>
                    
                   )
                   }
                  })()
        
                }
             
             
              </SoftBox>

              </SoftBox>
              
              

      </Card>
      <SoftBox py={3}>
      
      <Link to="/groups">
                
                <SoftTypography
                  variant="button"
                  fontWeight="medium"
                  color="dark"
                  onClick={() => this.setState({viewstatus: 'list'})}
                >
                  <FaBackward></FaBackward> my groups
                </SoftTypography>
              
            </Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link to={{pathname:link_to_schedules,search:querystring1} }>
                
                <SoftTypography
                  variant="button"
                  fontWeight="medium"
                  color="dark"
                  
                >
                  <FaForward></FaForward> schedules
                </SoftTypography>
              
            </Link>
      </SoftBox>
        <SoftBox mb={3}>
        {
                (() => {
                  if(this.state.viewstatus === 'list' ){ 
                   return (
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Normal Group Members</SoftTypography>
              {
              <SoftButton variant="gradient" size="small" color="info"  onClick={() => this.setState({viewstatus: 'create',member_type:'1',member_type_name:'Normal member',selected_member:'',group_admin_type:''})}>
                Add a new Member
              </SoftButton>
              }
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={member_columns} rows={this.state.normalmembers} />
            </SoftBox>


            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Guest Group Members</SoftTypography>
             {
              <SoftButton variant="gradient" size="small" color="info"  onClick={() => this.setState({viewstatus: 'create',member_type:'2',member_type_name:'Guest member',selected_member:'',group_admin_type:''})}>
                Add a new Guest Member
              </SoftButton>
             }
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={member_columns} rows={this.state.guestmembers} />
            </SoftBox>

          </Card>
        )}
        else if(this.state.viewstatus === 'createlink' )
        { 
          let search = window.location.search;
                    let params = new URLSearchParams(search);
                    let group_id = atob(params.get('id'));
                    let group_id_n = '/viewmembers?id='+params.get('id');

                    let sid = '';
                    if(params.get('sid')!=null)
                    {
                      sid =  atob(params.get('sid'));

                    }

                    let csid = '';
                    if(params.get('csid')!=null)
                    {
                      csid =  atob(params.get('csid'));

                    }
                    if(sid == '' && csid !='')
                    {
                      sid = csid;
                    }

          //call ajax to get details and fill
                    //alert(sid)
    
      const post_data = { series_id: sid };

      this.ref_link_heading.current= 'Generate new match schedule link';
      this.ref_link_heading_link.current='';

      
      var sel_g1_i = 'selected';
      var sel_g2_i = '';
      var sel_g3_i  = '';
    

//      alert(group_id)
//,{headers: {"Content-Type": "application/json"}}
    axios.post( global.config.app.backend_api_url + `matchschedule/getbyinvite_id`,post_data, { withCredentials: true }
    )  
      .then(res => {  

        if(sid  !== '')
        {

        
        //const normalmembers = res.data;  
        //console.log("fromtime"+res.data.link_data[0].from_time)
        console.log(res.data.link_data)
        //alert(res.data.link_data[0].invite_link)
        this.ref_link_heading_link.current=res.data.link_data[0].invite_link;
        document.getElementById("s_series_name").value = res.data.link_data[0].series_name;
        document.getElementById("s_match_date").value = res.data.link_data[0].match_date;
        document.getElementById("s_from_time").value = res.data.link_data[0].from_time;
        document.getElementById("s_to_time").value = res.data.link_data[0].to_time;
        document.getElementById("s_header_description").value = res.data.link_data[0].header_description;
        document.getElementById("s_footer_description").value = res.data.link_data[0].footer_description;

        document.getElementById("s_total_participants").value = res.data.link_data[0].total_participants;
        document.getElementById("s_total_guests_participants").value = res.data.link_data[0].total_guests_participants;
        

        document.getElementById("s_total_waiting_list").value = res.data.link_data[0].total_waiting_list;
        document.getElementById("s_add_delete_permission_cutoff_mins").value = res.data.link_data[0].add_delete_permission_cutoff_mins;
        
        if(csid=='')
        {
          document.getElementById("s_series_id").value = res.data.link_data[0].series_id;
        }
        
        document.getElementById("show_join_link_group_members_only").value = res.data.link_data[0].show_join_link_group_members_only;

        document.getElementById("enable_whatsapp_push").value = res.data.link_data[0].enable_whatsapp_push;
        document.getElementById("enable_telegram_push").value = res.data.link_data[0].enable_telegram_push;
        document.getElementById("enable_email_push").value = res.data.link_data[0].enable_email_push;
        document.getElementById("enable_browser_push").value = res.data.link_data[0].enable_browser_push;

        document.getElementById("mi_manual_sl_seq").value = res.data.link_data[0].manual_sl_seq;
        document.getElementById("photo_upload").value = res.data.link_data[0].photo_upload;
        



        document.getElementById("s_notify_automatically").value = res.data.link_data[0].notify_automatically;


        if(res.data.link_data[0].is_draft=='1')
        {
         // alert(res.data.link_data[0].is_draft)
         //console.log(document.getElementById('s_is_draft'))

        // document.getElementById('s_is_draft').setAttribute('checked', 'checked');
        document.getElementById('s_is_draft').value = "1";
       // alert(res.data.link_data[0].s_draft_publish_date + " " + res.data.link_data[0].s_draft_publish_time)
        document.getElementById("s_draft_publish_date").value = res.data.link_data[0].s_draft_publish_date;
        document.getElementById("s_draft_publish_time").value = res.data.link_data[0].s_draft_publish_time;
        
          this.checkIsDraft();
        }
        document.getElementById('s_status_fk').value = res.data.link_data[0].status_fk;
        



        //s_is_draft
        //s_draft_publish_time
        //s_draft_publish_date

        //alert(res.data.link_data[0].s_draft_publish_date)
        //document.getElementById("enable_browser_push").value = res.data.link_data[0].enable_browser_push;
        //s_draft_publish_date
        //s_draft_publish_time
        //s_is_draft

        
        //enable_browser_push


        
        document.getElementById("s_notify").checked = false;

        if(res.data.link_data[0].show_join_link_group_members_only == 0)
        {
          sel_g1_i = '';
          sel_g2_i =  'selected';
         // alert(sel_g2_i)
        }
        if(this.state.group_admin_flag == '1' ){ 

          // var link_to_create = "/creatematchlink?group_id="+btoa(group_id);
     
     
           let search = window.location.search;
           let params = new URLSearchParams(search);
           let series_id = '';
           
           if(params.get('sid') !=null)
           {
            series_id =  atob(params.get('sid'));
           }
             
           
           if(series_id!='')
           {
            this.ref_link_heading.current = 'Edit match link';
            document.getElementById("header_cap").innerHTML = 'Edit match link';
            document.getElementById("s_save_btn").innerHTML = 'Update';
            var html1 = " <br><a href='"+res.data.link_data[0].invite_link+"' target=_blank>Match link</a>. There are "+res.data.link_data[0].remaining_slots+' slots remaining.';
            document.getElementById("header_cap").innerHTML= document.getElementById("header_cap").innerHTML + html1;
           }
         }
  
        }// no rows 
        

      });

   



         return (
          <Card>

        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium" id='header_cap'>
          {this.ref_link_heading.current} {this.ref_link_heading_link.current}
          </SoftTypography>
        </SoftBox>
        
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form" id="groupform">
            <SoftBox mb={2}>
              <SoftInput placeholder="League Name" name="s_series_name" id="s_series_name" inputProps={{ maxLength: 100}}  />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput type="date" name="s_match_date" id="s_match_date" placeholder="Scheduled Date" inputProps={{ maxLength: 10}} />
            </SoftBox>

            <SoftBox mb={2}>
            <SoftInput type="time" name="s_from_time" id="s_from_time" placeholder="From Time" inputProps={{ maxLength: 10}} />
            </SoftBox>

            <SoftBox mb={2}>
            <SoftInput type="time" name="s_to_time" id="s_to_time" placeholder="To Time" inputProps={{ maxLength: 10}} />
            </SoftBox>

            <SoftBox mb={2}>
              <SoftInput placeholder="Header Part" name="s_header_description" id="s_header_description" multiline rows={5}  />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput placeholder="Footer Part" name="s_footer_description" id="s_footer_description" multiline rows={5}  />
            </SoftBox>


            <SoftBox mb={2}>
              <SoftInput placeholder="Total Participants" name="s_total_participants" id="s_total_participants" inputProps={{ maxLength: 3}}  />
            </SoftBox>

            <SoftBox mb={2}>
              <SoftInput placeholder="Guest Quota" name="s_total_guests_participants" id="s_total_guests_participants" inputProps={{ maxLength: 3}}  />
            </SoftBox>

            <SoftBox mb={2}>
              <SoftInput placeholder="Max. Waiting List" name="s_total_waiting_list" id="s_total_waiting_list" inputProps={{ maxLength: 2}}  />
            </SoftBox>
            

            <SoftBox mb={2}>
            <SoftInput type="text" name="s_add_delete_permission_cutoff_mins" id="s_add_delete_permission_cutoff_mins" placeholder="Cut Off Time for Removing Name?(Enter minutes)" inputProps={{ maxLength: 3}} />
            </SoftBox>
      
      <SoftBox mb={2}>

      <SoftTypography
                variant="button"
                fontWeight="regular"
                
                sx={{ cursor: "poiner", userSelect: "none" }}
              >
                Join Permission:
              </SoftTypography>

            <FormSelect size="sm" className="mb-9" name="show_join_link_group_members_only" id="show_join_link_group_members_only">
        <option value="1" selected={sel_g1_i}>To group members only</option>
        <option value="0" selected={sel_g2_i}>To anyone</option>
        <option value="2" >To anyone limited to whatsapp group members</option>
      </FormSelect>
      </SoftBox>
{/*
      <SoftBox mb={2}>

<SoftTypography
          variant="button"
          fontWeight="regular"
          
          sx={{ cursor: "poiner", userSelect: "none" }}
        >
          Joining member type by default:
        </SoftTypography>

      <FormSelect size="sm" className="mb-9" name="default_joining_type" id="default_joining_type">
  <option value="1" selected={(this.state.s_default_joining_type === "1") ? 'selected' : ''}>Default</option>
  <option value="0" selected={(this.state.s_default_joining_type === "2") ? 'selected' : ''}>Normal</option>
  <option value="0" selected={(this.state.s_default_joining_type === "3") ? 'selected' : ''}>Guest</option>
</FormSelect>
</SoftBox>
*/
}


      <SoftBox mb={2}>

      <SoftTypography
                variant="button"
                fontWeight="regular"
                
                sx={{ cursor: "poiner", userSelect: "none" }}
              >
                Whatsapp Group Notification on joining and removing of participants ?:
              </SoftTypography>

            <FormSelect size="sm" className="mb-9" name="enable_whatsapp_push" id="enable_whatsapp_push">
          {
                  (() => {
                  const listitems = this.state.series_notification_type;
                  //console.log(listitems);
                  //console.log("populating")
                  if (typeof(listitems) != "undefined")
                  {
                  //console.log(listitems)
              const rows = [];
              for (let i = 0; i < listitems.length; i++) 
              {

                //team_name,  team_logo, current_wallet_balance, current_players_count, players
                  if(listitems[i].show_for_group_notification=='1'){
                  rows.push(<option value={listitems[i].notification_type_id}>{listitems[i].notification_type_name}</option>);
                  }
              }
              return rows;
              //return <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>{rows}</SoftBox>;
            }

            })()
        
          }

      </FormSelect>
      </SoftBox>

<SoftBox mb={2}>

      <SoftTypography
                variant="button"
                fontWeight="regular"
                
                sx={{ cursor: "poiner", userSelect: "none" }}
              >
                Telegram Group Notification on joining and removing of participants?:
              </SoftTypography>

            <FormSelect size="sm" className="mb-9" name="enable_telegram_push" id="enable_telegram_push">
          {
                  (() => {
                  const listitems = this.state.series_notification_type;
                  //console.log(listitems);
                  //console.log("populating")
                  if (typeof(listitems) != "undefined")
                  {
                  //console.log(listitems)
              const rows = [];
              for (let i = 0; i < listitems.length; i++) 
              {

                //team_name,  team_logo, current_wallet_balance, current_players_count, players
                  if(listitems[i].show_for_group_notification=='1'){
                  rows.push(<option value={listitems[i].notification_type_id}>{listitems[i].notification_type_name}</option>);
                  }
              }
              return rows;
              //return <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>{rows}</SoftBox>;
            }

            })()
        
          }

      </FormSelect>
      </SoftBox>


<SoftBox mb={2}>

      <SoftTypography
                variant="button"
                fontWeight="regular"
                
                sx={{ cursor: "poiner", userSelect: "none" }}
              >
                Individual Email Notification on joining and removing of participants?:
              </SoftTypography>

            <FormSelect size="sm" className="mb-9" name="enable_email_push" id="enable_email_push">
          {
                  (() => {
                  const listitems = this.state.series_notification_type;
                  //console.log(listitems);
                  //console.log("populating")
                  if (typeof(listitems) != "undefined")
                  {
                  //console.log(listitems)
              const rows = [];
              for (let i = 0; i < listitems.length; i++) 
              {

                //team_name,  team_logo, current_wallet_balance, current_players_count, players
                  rows.push(<option value={listitems[i].notification_type_id}>{listitems[i].notification_type_name}</option>);
                  
              }
              return rows;
              //return <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>{rows}</SoftBox>;
            }

            })()
        
          }

      </FormSelect>
      </SoftBox>

<SoftBox mb={2}>

      <SoftTypography
                variant="button"
                fontWeight="regular"
                
                sx={{ cursor: "poiner", userSelect: "none" }}
              >
                Individual web browser push notification on joining and removing of participants?:
              </SoftTypography>

            <FormSelect size="sm" className="mb-9" name="enable_browser_push" id="enable_browser_push">
          {
                  (() => {
                  const listitems = this.state.series_notification_type;
                  //console.log(listitems);
                  //console.log("populating")
                  if (typeof(listitems) != "undefined")
                  {
                  //console.log(listitems)
              const rows = [];
              for (let i = 0; i < listitems.length; i++) 
              {

                //team_name,  team_logo, current_wallet_balance, current_players_count, players
                  rows.push(<option value={listitems[i].notification_type_id}>{listitems[i].notification_type_name}</option>);
                  
              }
              return rows;
              //return <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>{rows}</SoftBox>;
            }

            })()
        
          }

      </FormSelect>
      </SoftBox>

            

      
            <SoftBox display="flex" alignItems="center" >
            <SoftTypography
                variant="button"
                fontWeight="regular"
                
                sx={{  userSelect: "none" }}
              >
                Draft / Publish:
              </SoftTypography>
            <FormSelect size="sm" className="mb-9" name="s_is_draft" id="s_is_draft" onChange={this.checkIsDraft}  >
        <option value="0" >Publish</option>
        <option value="1" >Draft</option>
      </FormSelect>


            </SoftBox>

            <SoftBox mb={2} size="sm"  className="draft_item" style={{display:'none'}}>
              <SoftTypography size="sm" mb={1}>Publish Date
                <SoftInput type="date" name="s_draft_publish_date" id="s_draft_publish_date" placeholder="Scheduled Date" inputProps={{ maxLength: 10}} />
              </SoftTypography>
              <SoftTypography size="sm" mb={1}>Time
                <SoftInput type="time" name="s_draft_publish_time" id="s_draft_publish_time" placeholder="From Time" inputProps={{ maxLength: 10}} />
              </SoftTypography>
            </SoftBox>
            
            <SoftBox mb={2}  >
            <SoftTypography
                variant="button"
                fontWeight="regular"
                
                sx={{ cursor: "poiner", userSelect: "none" }}
              >
                Link Status:
              </SoftTypography>

            <FormSelect size="sm" className="mb-9" name="s_status_fk" id="s_status_fk">
        <option value="1" >Active</option>
        <option value="0" >Cancelled</option>
      </FormSelect>
      </SoftBox>

      <SoftBox display="flex" alignItems="center">
              <Checkbox value="1" id="s_notify"   />
              <SoftTypography
                variant="button"
                fontWeight="regular"
                
                sx={{ cursor: "poiner", userSelect: "none" }}
              >
                &nbsp;&nbsp;Notify all members now?
              </SoftTypography>
              
            </SoftBox>

            <SoftBox display="flex" alignItems="center" >
            <SoftTypography
                variant="button"
                fontWeight="regular"
                
                sx={{  userSelect: "none" }}
              >
                Regularly Notify Users Automatically (10 hrs gap) ?
              </SoftTypography>
            <FormSelect size="sm" className="mb-9" name="s_notify_automatically" id="s_notify_automatically"  >
        <option value="0" >No</option>
        <option value="1" >Yes</option>
      </FormSelect>


            </SoftBox>

            <SoftBox display="flex" alignItems="center" >
            <SoftTypography
                variant="button"
                fontWeight="regular"
                
                sx={{  userSelect: "none" }}
              >
                Manage seial number of players ?
              </SoftTypography>
              <FormSelect size="sm" className="mb-9" name="mi_manual_sl_seq" id="mi_manual_sl_seq" >
        <option value="0" >Automatic</option>
        <option value="1" >Manual(Reserve a serial number).Don't change when someone is removed  </option>
      </FormSelect>


            </SoftBox>



            <SoftBox display="flex" alignItems="center" >
            <SoftTypography
                variant="button"
                fontWeight="regular"
                
                sx={{  userSelect: "none" }}
              >
                Enable upload photo ?
              </SoftTypography>
            <FormSelect size="sm" className="mb-9" name="photo_upload" id="photo_upload" >
            <option value="0" >No</option>
            <option value="1" >Yes</option>
           
      </FormSelect>


            </SoftBox>


           


            
            <SoftBox mt={4} mb={1}>
              <input type='hidden' id='s_group_id' value={group_id}></input>
              <input type='hidden' id='s_series_id' value=''></input>
              

              <SoftButton variant="gradient" color="dark" fullWidth onClick={this.onClickGenerateLink} id='s_save_btn'>
                Generate
              </SoftButton>
            </SoftBox>
            
          </SoftBox>

          <Link to={group_id_n}>
                
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={() => this.setState({viewstatus: 'list'})}
                  >
                    Back
                  </SoftTypography>
                
              </Link>
              

        </SoftBox>

          </Card>
        
        )
        }
        else if(this.state.viewstatus === 'create' )
        { 
          let header_caption_member = 'New Member';
          var btn_caption='Add';
          let search = window.location.search;
          let params = new URLSearchParams(search);
          let group_id = atob(params.get('id'));
                    
          let checkboxstyle = {visibility:'visible'};
          //call ajax to get details and fill

    if(this.state.selected_member !='')
    {
      checkboxstyle = {visibility:'hidden'};
      btn_caption='Update';
      header_caption_member = 'Edit Member';
      const post_data = { group_id: group_id,member_id:this.state.selected_member };
//      alert(group_id)
//,{headers: {"Content-Type": "application/json"}}
    axios.post( global.config.app.backend_api_url + `groups/getplayerprofile`,post_data, { withCredentials: true }
    )  
      .then(res => {  
        //const normalmembers = res.data;  
        console.log("after112")
        console.log(res.data.link_data)
        var player_details = res.data.player_details[0];
        //alert(res.data.link_data[0].invite_link)
        document.getElementById("mobile_no").value = player_details.mobile_no;
        document.getElementById("member_name").value = player_details.member_name;

        document.getElementById("cric_heroes_profile_link").value = player_details.cric_heroes_profile_link;
        document.getElementById("overall_user_rating").value = player_details.overall_user_rating;
        document.getElementById("overall_comments").value = player_details.overall_comments;
        document.getElementById("public_description_about_member").value = player_details.public_description_about_member;
        document.getElementById("j_member_id").value = player_details.user_fk;
        document.getElementById("member_type").value = player_details.member_type;
        document.getElementById("group_admin").value = player_details.group_admin_type;
      });
    }
    const sel_options = [
      { value: '1', label: 'Normal' },
      { value: '2', label: 'Guest' }
    ];

    const link_to = '/viewmembers?id='+global_group_id;
    var sel_1 = '';
    var sel_2 = '';
    var sel_1a ='';
    var sel_2a = '';
   if(this.state.member_type == 1)
   {
    sel_1 = 'selected';
    sel_2 = '';
   }
   else if(this.state.member_type == 2)
   {
    sel_1 = '';
    sel_2 = 'selected';
   }

 


         return (
          <Card>

        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            {header_caption_member} 
          </SoftTypography>
        </SoftBox>
        
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form" id="groupform">
            <SoftBox mb={2}>
              <SoftInput placeholder="Mobile No" onKeyUp={this.onKeyDownSearch} name="mobile_no" id="mobile_no" inputProps={{ maxLength: 10}}  />
            </SoftBox>
            <SoftBox mb={2} id="j_members_list">
              
              
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput type="text" name="member_name" id="member_name" placeholder="Member Name" inputProps={{ maxLength: 60}} />
            </SoftBox>
            
            <SoftBox mb={2}>
              <SoftInput type="text" name="cric_heroes_profile_link" id="cric_heroes_profile_link" placeholder="CricHeroes Profile Link" inputProps={{ maxLength: 200}} />
            </SoftBox>

            <SoftBox mb={2}>
              <SoftInput type="text" name="overall_user_rating" id="overall_user_rating" placeholder="Player Rating" inputProps={{ maxLength: 3}} />
            </SoftBox>

            <SoftBox mb={2}>
              <SoftInput type="text" name="overall_comments" id="overall_comments" placeholder="Comments" inputProps={{ maxLength: 100}} />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput type="text" name="public_description_about_member" id="public_description_about_member" placeholder="Public Comments" inputProps={{ maxLength: 100}} />
            </SoftBox>

            <SoftBox mb={2}>

            <SoftTypography
                variant="button"
                fontWeight="regular"
                
                sx={{ cursor: "poiner", userSelect: "none" }}
              >
                Type:
              </SoftTypography>

            <FormSelect size="sm" className="mb-9" name="member_type" id="member_type" >
        <option value="1" selected={sel_1}>Normal</option>
        <option value="2" selected={sel_2}>Guest</option>
      </FormSelect>

            </SoftBox>

            <SoftBox mb={2}>

            <SoftTypography
                variant="button"
                fontWeight="regular"
                
                sx={{ cursor: "poiner", userSelect: "none" }}
              >
                Group Admin ?:
              </SoftTypography>

            <FormSelect size="sm" className="mb-9" name="group_admin" id="group_admin" >
        <option value="0" >No</option>
        <option value="1" >Yes</option>
      </FormSelect>

            </SoftBox>
            
            

            <SoftBox mt={4} mb={1}>
              <input type='hidden' id='j_member_id'></input>
              

              <SoftButton variant="gradient" color="dark" fullWidth onClick={this.onClickAddLink}>
                {btn_caption}
              </SoftButton>
            </SoftBox>
            
            <SoftBox display="flex" alignItems="center" style={checkboxstyle}>
              <Checkbox value="1" id="j_keep1"   />
              <SoftTypography
                variant="button"
                fontWeight="regular"
                
                sx={{ cursor: "poiner", userSelect: "none" }}
              >
                &nbsp;&nbsp;Need to add multiple? Tick this to keep the form.
              </SoftTypography>
              
            </SoftBox>

          </SoftBox>

          <Link to={link_to}>
                
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={this.onBacklist}
                  >
                    <FaBackward></FaBackward> list
                  </SoftTypography>
                
              </Link>
              {/*
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <Link to="/viewmembers?id=21">
                
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={() => this.setState({viewstatus: 'invite_link'})}
                  >
                    Generate invite link for {this.state.member_type_name}
                  </SoftTypography>
                
              </Link>
              */}
        </SoftBox>

          </Card>
        
        )
        }
        else if(this.state.viewstatus === 'uploaduserdp' )
        { 
          const link_to = '/viewmembers?id='+global_group_id;
         return (
          <Card>

        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Change Profile Pic - {this.state.selected_member_name}
          </SoftTypography>
        </SoftBox>
        
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form" id="dgroupform">
            
          <SoftBox mb={2}>
             <SoftInput type="file" name="dp_profile_pic" id="dp_profile_pic" placeholder="Profile pic" onChange={this.ondImageChange}  />
             
             
            </SoftBox>
            
          </SoftBox>
          {
            /*
            viewmembers?id=
            */
          }

          <Link to={link_to}>
                
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={() => this.setState({viewstatus: 'list'})}
                  >
                    Back 
                  </SoftTypography>
                
              </Link>
             

        </SoftBox>

          </Card>
        
        )
        }

        else if(this.state.viewstatus === 'edit' )
        { 
         return (
          <Card>

        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Update Group 
          </SoftTypography>
        </SoftBox>
        
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form" id="groupform">
            <SoftBox mb={2}>
              Group Name
              <SoftInput placeholder="Name" name="group_name" id="group_name" inputProps={{ maxLength: 60}}  />
            </SoftBox>
            <SoftBox mb={2}>
              Location
              <SoftInput type="text" name="location" id="location" placeholder="Location" inputProps={{ maxLength: 100}} />
            </SoftBox>
            <SoftBox mb={2}>Upload Logo
              <SoftInput type="file" name="group_logo" id="group_logo" placeholder="Upload Logo"  />
            </SoftBox>

            <SoftBox mb={2}>Fetch Telegram Main Group Name
              <SoftInput type="text" name="main_telegram_chat_id" id="main_telegram_chat_id" placeholder="Fetch Telegram Main Group Name"  inputProps={{ maxLength: 30,readOnly:true}} />
            </SoftBox>
            <SoftBox mb={2}>Fetch Telegram Guest Group Name
              <SoftInput type="text" name="guest_telegram_chat_id" id="guest_telegram_chat_id" placeholder="Fetch Telegram Guest Group Name"  inputProps={{ maxLength: 30,readOnly:true}} />
            </SoftBox>
            
            <SoftBox mt={4} mb={1}>
              <SoftButton variant="gradient" color="dark" fullWidth onClick={() => this.setState({viewstatus: 'list'})}>
                Update
              </SoftButton>
            </SoftBox>
            
          </SoftBox>

          <Link to="/groups">
                
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={() => this.setState({viewstatus: 'list'})}
                  >
                    <FaBackward></FaBackward> list
                  </SoftTypography>
                
              </Link>

        </SoftBox>

          </Card>
        
        )
        }

        else if(this.state.viewstatus === 'invite_link' )
        { 

          const link_to = '/viewmembers?id='+global_group_id;

         return (
          <Card>

        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Generate Invite Links
          </SoftTypography>
        </SoftBox>
        
        <SoftBox pt={2} pb={3} px={3}>

        <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            > Generated Links
              <Table columns={links_columns} rows={linkrows} />
            </SoftBox>

            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            > 
            <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={() => this.setState({viewstatus: 'list'})}
                  >
                    Generate a new invite link
                  </SoftTypography>

            </SoftBox>

          <SoftBox component="form" role="form" id="groupform">
            <SoftBox mb={2}>
              <SoftInput placeholder="Max Limit" name="max_join_limit_with_link" id="max_join_limit_with_link" inputProps={{ maxLength: 4}}  />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput type="text" name="link_expiry" id="link_expiry" placeholder="Link Expiry Date" inputProps={{ maxLength: 10}} />
            </SoftBox>
            
            <SoftBox mt={4} mb={1}>
              <SoftButton variant="gradient" color="dark" fullWidth >
                ..Generate..
              </SoftButton>
            </SoftBox>
            
          </SoftBox>

          <Link to={link_to}>
                
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={() => this.setState({viewstatus: 'list'})}
                  >
                    <FaBackward></FaBackward> members list
                  </SoftTypography>
                
              </Link>
              

        </SoftBox>

          </Card>
        
        )
        }

        
      })()
        
        }
        </SoftBox>
        
      </SoftBox>
      
      <Modal
        isOpen={this.state.show}
        onRequestClose={this.closeModal}
        style={customStyles}
        contentLabel="Modal"
      >
        <h2 >Profile</h2>
        
        <button variant="button" fontWeight="medium" color="info" onClick={this.closeModal}>close</button>
        <div id='i_profile_modal'>Player profile </div>
        
      </Modal>

      <Footer />
      
    </DashboardLayout>
    )  
  }  
}  

